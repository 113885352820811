body {
  color: #023766;
  font-size: 1.2rem;

  &, * {
    font-family: "Lato", "Helvetica", "sans-serif";
    box-sizing: border-box;
    text-decoration: none;
    text-underline: none;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  & {
    margin: 0;
    padding: 0;
  }
}

body {
  background: #E7ECEF;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Lato", "Helvetica", "sans-serif";
  color: #023766;
}

.print-document-footer,
.print-document-header,
.print-document-header-space,
.print-document-footer-space {
  height: 100px;
  width: 100%;
}

.print-document-header, .print-document-footer {
  position: fixed;
}

.print-document-header {
  top: 0;
}

.print-document-footer {
  bottom: 0;
}

@page {
  margin: 2mm
}
