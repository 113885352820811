.grid-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  height: 48px;
  border-top: none !important;
  background-color: inherit;
  font-size: 12px;
  line-height: 16px;
  color: currentColor;
  margin-top: 16px;

  .col-l {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .total {
      margin-right: 16px;
    }
  }

  .col-e {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;

    .grid-footer__pagination {
      display: flex;
      align-items: center;

      .grid-footer__pagination__label {
        margin-right: 8px;
      }

      .grid-footer__pagination__select {
        margin-right: 16px;
      }
    }
  }

  .grid-footer__info {
    display: flex;
    align-items: center;

    .grid-footer__info__label {
      margin-right: 8px;
    }
  }
}